import { useMemo, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import { CustomControl } from "./components/controls/Dialog";
import { InputDialog, InputSpec } from "./components/controls/InputDialog";
import { NotificationMessages } from "./components/impure/NotificationMessages";
import { signOutUser } from "./features/authentication/authenticationSlice";
import { toggleCursors } from "./features/userCursors/userCursorsSlice";
import { redo, setCountdownEnd, undo } from "./rootState";
import { AppDispatch, RootState } from "./store";
import { showDialog as showAuthorizationDialog } from './features/authorization/authorizationSlice';
import { showDialog as showSharingDialog } from './features/sharing/sharingSlice';
import { Avatar } from "./components/controls/Avatar";
import { PopupMenu } from "./components/controls/PopupMenu";
import { PopupButton } from "./components/controls/PopupButton";
import { NamedIcons } from "./components/controls/NamedIcon";
import { exportCsv, exportPdf, exportPng } from "./features/export";
import { useTranslation } from "react-i18next";
import { Tooltip } from "./components/controls/Tooltip";
import { NotificationsIndicator } from "./features/notifications/NotificationsIndicator";

export function MainLayout() {
  const { t } = useTranslation()
  const { pathname } = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch<AppDispatch>()
  const user = useSelector((state: RootState) => state.authentication.user)
  const token = useSelector((state: RootState) => state.authentication.token)
  const showCursors = useSelector((state: RootState) => state.userCursors.showCursors)
  const board = useSelector((state: RootState) => state.boardState.board)
  const [showTimerDialog, setShowTimerDialog] = useState<boolean>(false)

  const currentBoardId = useMemo(() => {
    const pathParts = pathname.split('/')
    if (pathParts.length > 2 && pathParts[1] === 'boards') {
      return pathParts[2]
    }
    return undefined
  }, [pathname])

  const [expandSidebar, setExpandSidebar] = useState<boolean>(false)
  const [showMainMenu, setShowMainMenu] = useState<boolean>(false)
  const [showDownloadOptions, setShowDownloadOptions] = useState<boolean>(false)

  const timerDialogInputSpecs: InputSpec[] = [
    {
      id: 'minutes',
      name: t('Minutes'),
      type: 'number',
      initialValue: 0,
    },
  ]

  const timerDialogControls: CustomControl[] = [
    {
      id: 'reset',
      text: t('Reset'),
      onClick: () => {
        setShowTimerDialog(false)
        dispatch(setCountdownEnd(undefined))
      },
    },
  ]

  const onCloseTimerDialog = (accepted: boolean, values?: {[id: string]: string | number | boolean}) => {
    setShowTimerDialog(false)
    if (accepted) {
      if (values!.minutes === 0) {
        dispatch(setCountdownEnd(undefined))
        return
      }

      const now = Date.now()
      const end = now + (values!.minutes as number) * 60000
      dispatch(setCountdownEnd(end))
    }
  }

  const signOut = () => {
    dispatch(signOutUser())
    navigate('/login')
  }

  const onClickUndo = () => {
    dispatch(undo())
  }
  useHotkeys('ctrl+z', onClickUndo);

  const onClickRedo = () => {
    dispatch(redo())
  }
  useHotkeys('ctrl+shift+z', onClickRedo);
  useHotkeys('ctrl+y', onClickRedo);

  const downloadPng = () => {
    exportPng(token!, currentBoardId!)
  }

  const downloadPdf = () => {
    exportPdf(token!, currentBoardId!)
  }

  const downloadCsv = () => {
    exportCsv(board!)
  }

  return (
    <div className={`${showMainMenu ? 'show-mainMenu' : ''} ${expandSidebar ? 'expanded-sideMenu' : ''}`}>
      <header className={`header ${expandSidebar ? 'expand' : ''}`}>
        <div className="container px-xl-0">
          <div className="header-content">

            {/* header logo */}
            <Link className="header-logoBox" to="/">
              <img className="header-logo" src="/img/share2flow.png" alt="header-logo" />
            </Link>
            {/* header nav */}
            <ul className="header-nav header-userNav">
              <li className="header-navItem d-xl-none">
                <div className="header-navLink mainMenu_showBtn" onClick={() => setShowMainMenu(true)}>
                  <div className="header-navIcon">
                    <i className="fi fi-rr-menu-burger"></i>
                  </div>
                </div>
              </li>
              <li className="header-navItem">
                <Link className="header-navLink header-userNavLink" to="/">
                  <div className="header-navIcon">
                    <Avatar avatarUrl={user?.avatarUrl} size={36} />
                  </div>
                  <div className="header-navText">
                    {user?.name}
                  </div>
                </Link>
              </li>
              <li className="header-navItem">
                <NavLink className="header-navLink header-notifNavLink" to="notifications">
                  <Tooltip className="header-navIcon" title={t('Notifications')}>
                    <i className="fi fi-rr-bell"></i>
                    <NotificationsIndicator />
                  </Tooltip>
                  <div className="header-navText">
                    {t('Notifications')}
                  </div>
                </NavLink>
              </li>
            </ul>


            {/* header menu */}
            <div className="header-mainMenu">
              <button className="btn border mainMenu_hideBtn" onClick={() => setShowMainMenu(false)}>
                <i className="fi fi-rr-cross"></i>
              </button>
              {/* board controls */}
              {currentBoardId &&
                <ul className="header-nav">
                  <li className="header-navItem">
                    <NavLink className="header-navLink" end to="/">
                      <Tooltip className="header-navIcon" title={t('Home')}>
                        <i className="fi fi-rr-home"></i>
                      </Tooltip>
                      <div className="header-navText">
                        {t('Home')}
                      </div>
                    </NavLink>
                  </li>
                  <li className="header-navItem">
                    <PopupMenu isVisible={showDownloadOptions} onDismiss={() => setShowDownloadOptions(false)} width={200} anchor="TopLeft">
                      <PopupButton
                        text="PNG"
                        iconName={NamedIcons.FILE_IMAGE}
                        onClick={() => {
                          downloadPng()
                          setShowDownloadOptions(false)
                        }}
                      />
                      <PopupButton
                        text="PDF"
                        iconName={NamedIcons.FILE_PDF}
                        onClick={() => {
                          downloadPdf()
                          setShowDownloadOptions(false)
                        }}
                      />
                      <PopupButton
                        text="CSV"
                        iconName={NamedIcons.FILE_CSV}
                        onClick={() => {
                          downloadCsv()
                          setShowDownloadOptions(false)
                        }}
                      />
                    </PopupMenu>
                    <a className="header-navLink" onClick={() => setShowDownloadOptions(true)}>
                      <Tooltip className="header-navIcon" title={t('Download')}>
                        <i className="fi fi-rr-download"></i>
                      </Tooltip>
                      <div className="header-navText">
                        {t('Download')}
                      </div>
                    </a>
                  </li>
                  <li className="header-navItem">
                    <a className="header-navLink" onClick={() => dispatch(showAuthorizationDialog())}>
                      <Tooltip className="header-navIcon" title={t('Board Members')}>
                        <i className="fi fi-rr-users"></i>
                      </Tooltip>
                      <div className="header-navText">
                        {t('Board Members')}
                      </div>
                    </a>
                  </li>
                  <li className="header-navItem">
                    <a className="header-navLink" onClick={() => dispatch(showSharingDialog())}>
                      <Tooltip className="header-navIcon" title={t('Invite User')}>
                        <i className="fi fi-rr-paper-plane"></i>
                      </Tooltip>
                      <div className="header-navText">
                        {t('Invite User')}
                      </div>
                    </a>
                  </li>
                  <li className="header-navItem">
                    <a className="header-navLink" onClick={() => setShowTimerDialog(true)}>
                      <Tooltip className="header-navIcon" title={t('Set Timer')}>
                        <i className="fi fi-rr-clock"></i>
                      </Tooltip>
                      <div className="header-navText">
                        {t('Set Timer')}
                      </div>
                    </a>
                  </li>
                  <li className="header-navItem">
                    <a className={`header-navLink ${showCursors && 'checked'}`} onClick={() => dispatch(toggleCursors())}>
                      <Tooltip className="header-navIcon" title={t('Show Users\' Cursors')}>
                        <i className="fi fi-rr-cursor"></i>
                      </Tooltip>
                      <div className="header-navText">
                        {t('Show Users\' Cursors')}
                      </div>
                    </a>
                  </li>
                  <li className="header-navItem">
                    <a className="header-navLink" onClick={onClickUndo}>
                      <Tooltip className="header-navIcon" title={t('Undo')}>
                        <i className="fi fi-rr-undo"></i>
                      </Tooltip>
                      <div className="header-navText">
                        {t('Undo')}
                      </div>
                    </a>
                  </li>
                  <li className="header-navItem">
                    <a className="header-navLink" onClick={onClickRedo}>
                      <Tooltip className="header-navIcon" title={t('Redo')}>
                        <i className="fi fi-rr-redo"></i>
                      </Tooltip>
                      <div className="header-navText">
                        {t('Redo')}
                      </div>
                    </a>
                  </li>
                </ul>
              }
              {/* end board controls */}
              {/* header nav */}
              <ul className="header-nav">
                {!currentBoardId &&
                  <>
                    <li className="header-navItem">
                      <NavLink className="header-navLink" end to="/">
                        <Tooltip className="header-navIcon" title={t('Home')}>
                          <i className="fi fi-rr-home"></i>
                        </Tooltip>
                        <div className="header-navText">
                          {t('Home')}
                        </div>
                      </NavLink>
                    </li>
                    <li className="header-navItem">
                      <NavLink className="header-navLink" to="/my-flows">
                        <Tooltip className="header-navIcon" title={!expandSidebar ? `${t('My Flows')} / ${t('FlowBoard')}` : undefined}>
                          <i className="fi fi-rr-apps"></i>
                        </Tooltip>
                        <div className="header-navText">
                          {t('My Flows')} / {t('FlowBoard')}
                        </div>
                      </NavLink>
                    </li>
                    <li className="header-navItem">
                      <NavLink className="header-navLink" end to="/settings">
                        <Tooltip className="header-navIcon" title={t('Settings')}>
                          <i className="fi fi-rr-settings"></i>
                        </Tooltip>
                        <div className="header-navText">
                          {t('Settings')}
                        </div>
                      </NavLink>
                    </li>
                    <li className="header-navItem">
                      <NavLink className="header-navLink" to="/videos">
                        <Tooltip className="header-navIcon" title={t('Videos')}>
                          <i className="fi fi-rr-play-alt"></i>
                        </Tooltip>
                        <div className="header-navText">
                          {t('Videos')}
                        </div>
                      </NavLink>
                    </li>
                    <li className="header-navItem">
                      <NavLink className="header-navLink" to="/settings/security">
                        <Tooltip className="header-navIcon" title={t('Security')}>
                          <i className="fi fi-rr-lock"></i>
                        </Tooltip>
                        <div className="header-navText">
                          {t('Security')}
                        </div>
                      </NavLink>
                    </li>
                    <li className="header-navItem">
                      <NavLink className="header-navLink" to="/to-do">
                        <Tooltip className="header-navIcon" title={!expandSidebar ? 'To Do´s' : undefined}>
                          <i className="fi fi-rr-time-check"></i>
                        </Tooltip>
                        <div className="header-navText">
                          To Do´s
                        </div>
                      </NavLink>
                    </li>
                    <li className="header-navItem">
                      <NavLink className="header-navLink" to="/statistics">
                        <Tooltip className="header-navIcon" title={t('Statistics')}>
                          <i className="fi fi-rr-chart-line-up"></i>
                        </Tooltip>
                        <div className="header-navText">
                          {t('Statistics')}
                        </div>
                      </NavLink>
                    </li>
                    <li className="header-navItem">
                      <NavLink className="header-navLink" to="/billing/subscription">
                        <Tooltip className="header-navIcon" title={t('Subscription')}>
                          <i className="fi fi-rr-file-invoice-dollar"></i>
                        </Tooltip>
                        <div className="header-navText">
                          {t('Subscription')}
                        </div>
                      </NavLink>
                    </li>
                    {user?.isAdmin &&
                      <li className="header-navItem">
                        <NavLink className="header-navLink" to="/management/users">
                          <Tooltip className="header-navIcon" title={t('Management')}>
                            <i className="fi fi-rr-user-gear"></i>
                          </Tooltip>
                          <div className="header-navText">
                            {t('Management')}
                          </div>
                        </NavLink>
                      </li>
                    }
                  </>
                }
                <li className="header-navItem">
                  <a className="header-navLink" onClick={() => signOut()}>
                    <Tooltip className="header-navIcon" title={t('Logout')}>
                      <i className="fi fi-rr-power"></i>
                    </Tooltip>
                    <div className="header-navText">
                      {t('Logout')}
                    </div>
                  </a>
                </li>
              </ul>
              {/* header nav */}
              <ul className="header-nav d-none d-xl-block">
                <li className="header-navItem">
                  <div className="header-navLink sidebar_toggleBtn" onClick={() => setExpandSidebar(!expandSidebar)}>
                    <Tooltip className="header-navIcon" title={t('Expand')}>
                      <i className="fi fi-rr-angle-circle-right expand"></i>
                      <i className="fi fi-rr-angle-circle-left exp_collapse"></i>
                    </Tooltip>
                    <div className="header-navText">
                      <span className="expand">{t('Expand')}</span>
                      <span className="exp_collapse">{t('Collapse')}</span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="header-backdrop" onClick={() => setShowMainMenu(false)}></div>
      </header>
      {/* .................. end main header .................. */}

      {/* .................. start main section .................. */}
      <Outlet />
      {/* .................. end main section .................. */}

      <InputDialog title={t('Countdown Timer')} inputSpecs={timerDialogInputSpecs} controls={timerDialogControls} open={showTimerDialog} onClose={onCloseTimerDialog} />
      <NotificationMessages />
    </div>
  )
}
