import { useRef, useState } from "react";
import { ArcherElement } from "react-archer";
import { AnchorPositionType } from "react-archer/lib/types";
import { CellCoords, Reference } from "share2flow-board";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { areArrowEndReferencesEqual, ArrowEndReference, clearTarget, endDrawing, setTarget, startDrawing } from "../../features/arrowDrawing/arrowDrawingSlice";
import { addReference, findCellCoordsById, useCellCoordsById, useReferencesForSource } from "../../rootState";

interface ArrowsProps {
  id: string,
  tag?: string,
  className?: string,
  renderControls?: (clickedArrow: Reference | undefined, clearClickedArrow: () => void) => React.ReactNode,
  defaultColor?: string,
}

export function Arrows({ children, id, tag, className, renderControls, defaultColor }: React.PropsWithChildren<ArrowsProps>) {
  const isDrawing = useSelector((state: RootState) => state.arrowDrawing.isDrawing)
  const source = useSelector((state: RootState) => state.arrowDrawing.source)
  const target = useSelector((state: RootState) => state.arrowDrawing.target)
  const board = useSelector((state: RootState) => state.boardState.board)
  const dispatch = useDispatch()

  const sourceCoords = useCellCoordsById(source?.id)
  const selfCoords = useCellCoordsById(id)!
  const referencesM = useReferencesForSource(id, tag)
  const references = referencesM ? [...referencesM] : []
  const containerRef = useRef<HTMLDivElement>(null)

  const thisArrowReference: ArrowEndReference = {id, tag: tag || ''}

  const isSource = source && areArrowEndReferencesEqual(source!, {id, tag: tag || ''})
  const isSelf = source?.id === id
  const isSection = sourceCoords?.section === selfCoords.section

  let canDraw = false

  if (isDrawing) {
    const sourceTagY = source?.tag?.toLowerCase().includes('top') ? -1 : source?.tag?.toLowerCase().includes('bottom') ? 1 : 0
    const sourceTagX = source?.tag?.toLowerCase().includes('left') ? -1 : source?.tag?.toLowerCase().includes('right') ? 1 : 0
    const selfTagY = tag?.toLowerCase().includes('top') ? -1 : tag?.toLowerCase().includes('bottom') ? 1 : 0
    const selfTagX = tag?.toLowerCase().includes('left') ? -1 : tag?.toLowerCase().includes('right') ? 1 : 0
    const xDir = selfCoords.column < (sourceCoords?.column || 0) ? -1 : selfCoords.column > (sourceCoords?.column || 0) ? 1 : 0
    const yDir = selfCoords.row < (sourceCoords?.row || 0) ? -1 : selfCoords.row > (sourceCoords?.row || 0) ? 1 : 0
    canDraw = (yDir === sourceTagY && sourceTagY * selfTagY == -1) ||
              (xDir === sourceTagX && sourceTagX * selfTagX == -1)
  }

  const onMouseOver = () => {
    if (isDrawing && source!.id !== thisArrowReference.id && canDraw) {
      dispatch(setTarget(thisArrowReference))
    }
  }

  const onMouseOut = () => {
    if (isDrawing) {
      dispatch(clearTarget())
    }
  }

  const setWindowMouseClickListener = () => {
    const handleClickOutside = () => {
      dispatch(endDrawing())
      window.removeEventListener('click', handleClickOutside)
    }

    setTimeout(() => {
      window.addEventListener('click', handleClickOutside)
    }, 10)
  }

  const onClick = () => {
    if (!isDrawing) {
      dispatch(startDrawing(thisArrowReference))
      setWindowMouseClickListener()
      return
    }

    dispatch(endDrawing())

    if (source!.id === thisArrowReference.id || !canDraw) {
      return
    }

    dispatch(addReference({
      sourceId: source!.id,
      sourceTag: source!.tag || '',
      targetId: target!.id,
      targetTag: target!.tag || '',
      color: defaultColor,
    }))
  }

  if (isDrawing && target && areArrowEndReferencesEqual(source!, {id, tag: tag || ''})) {
    references.push({
      sourceId: id,
      sourceTag: tag || '',
      targetId: target!.id,
      targetTag: target!.tag,
      color: defaultColor,
    })
  }

  const [hoveredArrow, setHoveredArrow] = useState<Reference | undefined>(undefined)
  const [clickedArrow, setClickedArrow] = useState<Reference | undefined>(undefined)

  const onMouseEnterArrow = (reference: Reference) => {
    setHoveredArrow(reference)
  }

  const onMouseLeaveArrow = () => {
    setHoveredArrow(undefined)
  }

  const onClickArrow = (e: React.MouseEvent, reference: Reference) => {
    setClickedArrow(reference)
  }

  return (
    <ArcherElement
      id={`${id} ${tag || ''}`}
      relations={references.map((reference) => {
        let targetAnchor: AnchorPositionType = 'middle'
        let sourceAnchor: AnchorPositionType = 'middle'

        const targetCoords = findCellCoordsById(board, reference.targetId)
        const sourceLeftOfTarget = targetCoords ? selfCoords.column < targetCoords.column : false
        const targetLeftOfSource = targetCoords ? targetCoords.column < selfCoords.column : false
        const sourceAboveTarget = targetCoords ? selfCoords.row < targetCoords.row : false
        const targetAboveSource = targetCoords ? targetCoords.row < selfCoords.row : false

        if (reference.sourceTag) {
          switch (reference.sourceTag) {
            case 'top':
              sourceAnchor = 'top'
            break
            case 'bottom':
              sourceAnchor = 'bottom'
            break
            case 'left':
              sourceAnchor = 'left'
            break
            case 'right':
              sourceAnchor = 'right'
            break
            case 'topLeft':
              if (!targetAboveSource) {
              sourceAnchor = 'left'
            } else {
              sourceAnchor = 'top'
            }
            break
            case 'topRight':
              if (!targetAboveSource) {
              sourceAnchor = 'right'
            } else {
              sourceAnchor = 'top'
            }
            break
            case 'bottomLeft':
              if (sourceAboveTarget) {
              sourceAnchor = 'bottom'
            } else {
              sourceAnchor = 'left'
            }
            break
            case 'bottomRight':
              if (sourceAboveTarget) {
              sourceAnchor = 'bottom'
            } else {
              sourceAnchor = 'right'
            }
            break
          }
        }

        if (reference.targetTag) {
          switch (reference.targetTag) {
            case 'top':
              targetAnchor = 'top'
            break
            case 'bottom':
              targetAnchor = 'bottom'
            break
            case 'left':
              targetAnchor = 'left'
            break
            case 'right':
              targetAnchor = 'right'
            break
            case 'topLeft':
              if (sourceLeftOfTarget) {
              targetAnchor = 'left'
            } else {
              targetAnchor = 'top'
            }
            break
            case 'topRight':
              if (targetLeftOfSource) {
              targetAnchor = 'right'
            } else {
              targetAnchor = 'top'
            }
            break
            case 'bottomLeft':
              if (targetLeftOfSource) {
              targetAnchor = 'bottom'
            } else {
              targetAnchor = 'left'
            }
            break
            case 'bottomRight':
              if (!sourceLeftOfTarget) {
              targetAnchor = 'right'
            } else {
              targetAnchor = 'bottom'
            }
            break
          }
        }

        if (targetCoords && targetCoords.column === selfCoords.column) {
          if (targetCoords.row < selfCoords.row) {
            targetAnchor = 'bottom'
            sourceAnchor = 'top'
          } else if (targetCoords.row > selfCoords.row) {
            targetAnchor = 'top'
            sourceAnchor = 'bottom'
          } else {
            targetAnchor = 'middle'
            sourceAnchor = 'middle'
          }
        }

        return {
          targetId: `${reference.targetId} ${reference.targetTag || ''}`,
          targetAnchor,
          sourceAnchor,
          label: reference.label
            ? <div className="w-40 h-10 flex items-center justify-center">
              <div className="text-[0.6rem] bg-primary-purple text-white shadow-md px-2 py-1 text-center rounded-md cursor-pointer" onClick={(e) => onClickArrow(e, reference)}>{reference.label}</div>
            </div>
            : undefined,
          style: {
            strokeColor: hoveredArrow === reference
              ? '#3b82f6'
              : reference.color || '#404040',
          },
          domAttributes: {
            onMouseOver: () => onMouseEnterArrow(reference),
            onMouseOut: () => onMouseLeaveArrow(),
            onClick: (e) => onClickArrow(e, reference),
          },
        }
      })}
    >
      <div>
        <div className={`absolute inset-0 ${className || ''} ${isDrawing && !isSelf && isSection && canDraw ? 'animate-ping border-green-500' : ''}`}></div>
        <div
          ref={containerRef}
          className={`${className || ''} ${isSource ? 'border-orange-500 scale-125 transition-transform duration-150 ease-in-out' : ''} ${isDrawing && !isSelf && isSection && canDraw ? 'border-green-500' : ''} hover:border-red-500 relative`}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          onClick={onClick}
        >
          {children}
        </div>
        {renderControls && renderControls(clickedArrow, () => setClickedArrow(undefined))}
      </div>
    </ArcherElement>
  )
}
