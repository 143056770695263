import { createSlice, Middleware, PayloadAction } from '@reduxjs/toolkit'
import { io, Socket } from "socket.io-client";
import config from "./config";
import { showNotificationMessage } from './features/notificationMessages/notificationMessagesSlice';

export const createSocket = (token: string, boardId: string) => {
  return io(config.backendUrl, {
    auth: {
      token,
      boardId,
    }
  })
}

export const createSocketMiddleware: Middleware<{}> = (middlewareApi) => {
  return next => {
    let socket: Socket | undefined = undefined

    return action => {
      if (typeof action === "object") {
        if (action.type === "socket/setBoardId") {
          const token = middlewareApi.getState().authentication.token
          const boardId = action.payload
          if (socket) {
            socket.disconnect()
            socket = undefined
          }
          if (token && boardId) {
            socket = createSocket(token, boardId)
            socket.on('action', (payload) => {
              if (payload.type === 'notificationMessages/showNotificationMessage') {
                middlewareApi.dispatch(showNotificationMessage(payload.payload) as any)
              } else {
                middlewareApi.dispatch(payload)
              }
            })
            socket.on('authorized', () => {
              socket?.emit('requestInit')
            })
          }
          return
        } else if (action.type === "socket/sendMessage") {
          if (socket && socket.connected) {
            socket.emit(action.payload.event, action.payload.data)
          }
          return
        } else if (action.type === "socket/sendMessageWithCallback") {
          if (socket && socket.connected) {
            socket.emit(action.payload.event, action.payload.data, action.payload.callback)
          }
          return
        }
      }
      next(action)
    }
  }
}

export interface SocketMessagePayload {
  event: string,
  data: any,
}

export interface SocketMessageWithCallbackPayload {
  event: string,
  data: any,
  callback: Function,
}

export const socketSlice = createSlice({
  name: 'socket',
  initialState: {},
  reducers: {
    setBoardId: (state, action: PayloadAction<string | undefined>) => {},
    sendMessage: (state, action: PayloadAction<SocketMessagePayload>) => {},
    sendMessageWithCallback: (state, action: PayloadAction<SocketMessageWithCallbackPayload>) => {},
  },
})

export const { setBoardId, sendMessage, sendMessageWithCallback } = socketSlice.actions

export default socketSlice.reducer
